import React from "react";
import partner1 from "../assets/partner1.png";
import partner2 from "../assets/partner2.png";
import partner3 from "../assets/partner3.png";
import partner4 from "../assets/partner4.png";

function Partners() {
  return (
    <div className="w-full">
      <h1 className="my-font mt-[40px] mb-6 text-center md:text-5xl text-4xl underline underline-offset-[16px] ">
        Our Partner
      </h1>
      <div className="flex lg:flex-row flex-col w-11/12 mx-auto items-center space-y-[10px]">
        <div className="flex lg:w-1/2 w-10/12">
          <div className="partners-div">
            <img className="w-[80%]" src={partner1} alt="" />
          </div>
          <div className="partners-div">
            <img className="w-[85%]" src={partner2} alt="" />
          </div>
        </div>
        <div className="flex lg:w-1/2 w-10/12">
          <div className="partners-div">
            <img className="w-[62%]" src={partner3} alt="" />
          </div>
          <div className="partners-div">
            <img className="w-[70%]" src={partner4} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
