import React, { useEffect } from "react";
import img1 from "../assets/img1.png";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="flex flex-col bg-div items-center overflow-x-hidden justify-center w-full w-screen min-h-screen bg-gray-100  lg:p-8 md:p-8 p-4"
      style={{ backgroundImage: `url(${img1})` }}
    >
      <div className="max-w-4xl card-font bg-white bg-opacity-10 backdrop-blur-md lg:p-8 md:p-8 p-4 rounded-lg shadow-lg">
        <h1 className="text-6xl font-bold mb-4 font-base my-font text-center">
          Privacy Policy
        </h1>
        <p className="text-sm text-nowrap my-desc text-gray-200 mb-8 text-center">
          Last updated: September 18, 2024
        </p>

        <p className="mb-4">
          This Privacy Policy describes Our policies and procedures on the
          collection, use, and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>
        <p className="mb-4">
          We use Your Personal data to provide and improve the Service. By using
          the Service, you agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">
          Collecting and Using Your Personal Data
        </h2>

        <h3 className="text-xl font-semibold mt-4 mb-2">
          Types of Data Collected
        </h3>

        <h4 className="font-semibold mt-2 mb-1">Personal Data</h4>
        <p className="mb-4">
          While using Our Service, we may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul className="list-disc list-inside mb-4 pl-4">
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
          <li>Usage Data</li>
        </ul>

        <h4 className="font-semibold mt-2 mb-1">Usage Data</h4>
        <p className="mb-4">
          Usage Data is collected automatically when using the Service. Usage
          Data may include information such as Your Device's IP address, browser
          type, browser version, the pages of our Service that You visit, the
          time and date of Your visit, the time spent on those pages, unique
          device identifiers, and other diagnostic data.
        </p>

        <h3 className="text-xl font-semibold mt-6 mb-2">
          Tracking Technologies and Cookies
        </h3>
        <p className="mb-4">
          We use Cookies and similar tracking technologies to track the activity
          on Our Service and store certain information. The technologies We use
          may include:
        </p>
        <ul className="list-disc list-inside mb-4 pl-4">
          <li>
            <strong>Cookies or Browser Cookies:</strong> A cookie is a small
            file placed on Your Device.
          </li>
          <li>
            <strong>Web Beacons:</strong> Small electronic files used to count
            users and track interactions.
          </li>
        </ul>

        <h3 className="text-xl font-semibold mt-6 mb-2">
          Use of Your Personal Data
        </h3>
        <p className="mb-4">
          The Company may use Personal Data for the following purposes:
        </p>
        <ul className="list-disc list-inside mb-4 pl-4">
          <li>To provide and maintain our Service</li>
          <li>To manage Your Account</li>
          <li>To contact You</li>
          <li>For business transfers</li>
        </ul>

        {/* New Section: Objectionable Content and User Conduct */}
        <h3 className="text-xl font-semibold mt-6 mb-2">
          Objectionable Content and User Conduct
        </h3>
        <p className="mb-4">
          We are committed to maintaining a safe and respectful environment within the app. By using this app, you agree not to post, share, or engage in any form of objectionable content, including but not limited to:
        </p>
        <ul className="list-disc list-inside mb-4 pl-4">
          <li>Offensive language or behavior</li>
          <li>Harassment, threats, or abuse</li>
          <li>Content that is illegal, harmful, or violates the rights of others</li>
        </ul>
        <p className="mb-4">
          Failure to comply with these terms may result in the suspension or termination of your account. We reserve the right to remove any content that violates these guidelines.
        </p>

        <h2 className="text-2xl font-bold mt-8 mb-4">Contact Us</h2>
        <p className="mb-4">
          If you have any questions about this Privacy Policy, you can contact
          us:
        </p>
        <ul className="list-disc list-inside pl-4">
          <li className="text-nowrap">
            By email:{" "}
            <a
              href="mailto:ratofyio@gmail.com"
              className="text-yellow-200 no-underline"
            >
              ratofyio@gmail.com
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
