import React, { useEffect, useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import loader from "../assets/loader.svg";
import img1 from "../assets/img1.png";

function Contact() {
  const [loading, setLoading] = useState(true);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className="flex flex-col bg-div items-center overflow-x-hidden justify-center w-full w-screen min-h-screen bg-gray-100  lg:p-8 md:p-8 p-4"
      style={{ backgroundImage: `url(${img1})` }}
    >
      <div className="w-full overflow-hidden rounded-lg bg-white max-w-4xl h-[80vh] lg:w-[500px] md:w-[500px] lg:h-[700px] md:h-[700px] bg-white lg:p-8 md:p-8 p-4 rounded-lg shadow-lg">

        {/* Loader */}
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-transparent z-40">
            <div className="loader bg-transparent">
              <img src={loader} className="w-[100px] bg-transparent h-[100px]" />
            </div>
          </div>
        )}

        <iframe
          src="https://tally.so/r/3jWgDa?transparentBackground=1"
          className="w-full h-full border-0"
          title="Contact form"
          onLoad={handleIframeLoad}
        ></iframe>
      </div>
    </div>
  );
}

export default Contact;
